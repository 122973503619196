import { twMerge } from "tailwind-merge";
import ProductCard from "./ProductCard";
import type { ProductCardType } from "~/graphql/fragments/types";
import { nonNullable } from "~/util/nonNullable";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  docs: Array<ProductCardType | null>;
  look?: "grid" | "list";
}

const ProductsGrid: React.FC<Props> = ({
  docs,
  look = "grid",
  className,
  ...props
}) => {
  return (
    <div
      {...props}
      className={twMerge(
        "gap-4 ",
        look === "grid" && "sm:grid sm:grid-cols-2 md:grid-cols-3",
        look === "list" && "flex flex-col",
        className,
      )}
    >
      {docs.filter(nonNullable).map((item, index) => {
        return <ProductCard product={item} key={index} look={look} />;
      })}
    </div>
  );
};
export default ProductsGrid;
